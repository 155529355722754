/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#821305',
          // secondary: '#948472',
          secondary: '#cabaa4',
          background: '#FFEFD6',
          // background: '#cabaa4',
          red: '#821204',
          // brown: '#948472',
          brown: '#cabaa4',
          black: '#0f0f0e',
          'white-accent': '#FFEFD6',
          'white-accent-darken-3': '#201300',
          'white-accent-darken-4': '#221500'
        }
      }
    }
  }
})
