<template>
  <v-app>
    <main-nav />

    <v-main
      :style="`margin-top: ${mobileStore.getMobile() || mobileStore.getTablet() ? '100px' : ''};`">
      <router-view />
    </v-main>

    <main-footer />

    <cookie-banner v-if="!showCookieConsent" />
  </v-app>
</template>

<script lang="ts" setup>
import MainNav from '@/components/MainNav.vue'
import MainFooter from '@/components/MainFooter.vue'
import CookieBanner from '@/components/Misc/CookieBanner.vue'
import mitgliederData from '@/data/mitglieder.json'
import hundeData from '@/data/hunde.json'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useDisplay } from 'vuetify'
import { useAppStore } from '@/store/app'
import { useMobileStore } from '@/store/displayStore'
import canUseWebP from '@/utility/canUseWebP'
import { getCookies } from 'typescript-cookie'

const appStore = useAppStore()
const mobileStore = useMobileStore()
const { width, mobile, smAndUp } = useDisplay()
const showCookieConsent = ref(false)

const setMobile = () => {
  mobileStore.setMobile(mobile.value)
  mobileStore.setTablet(computed(() => mobile.value && smAndUp.value).value)
}

appStore.$subscribe((mutation, state) => {
  if (mutation.type === 'direct') {
    showCookieConsent.value = Object.keys(state.cookies).length > 0
  }
})

watch(width, () => {
  setMobile()
})

onBeforeMount(() => {
  const cookies = getCookies()
  appStore.setCookies(cookies)
  showCookieConsent.value = Object.keys(appStore.getCookies()).length > 0

  appStore.setWebpSupport(canUseWebP())

  setMobile()

  appStore.setElementSize({
    nav: 150,
    footer: mobileStore.getMobile() && !mobileStore.getTablet() ? 400 : 180
  })

  mitgliederData.forEach((mitglied) => {
    appStore.setMitglied(mitglied)
  })

  hundeData.forEach((hund) => {
    appStore.setHund(hund)
  })
})
</script>

<style lang="scss">
* {
  font-family: Roboto, sans-serif;
}

p {
  font-size: 18px;
  color: var(--night);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Hokyaa, sans-serif;
  color: var(--night);
}

//<!-- Sources: -->
//<!-- https://www.falkenklinge.de/ -->
//<!-- https://www.wuerttembergerritter.de/ -->
//<!-- https://www.unterfreyembanner.de/ -->
</style>
