<template>
  <v-list-item class="ma-0 pa-0" height="23px">
    <router-link :to="{ name: 'Home' }" class="text-decoration-none text-white">
      <h2>Home</h2>
    </router-link>
  </v-list-item>

  <v-list-item class="ma-0 pa-0" height="23px">
    <router-link :to="{ name: 'AboutUs' }" class="text-decoration-none text-white">
      <h2>Über uns</h2>
    </router-link>
  </v-list-item>

  <v-list-item class="ma-0 pa-0" height="23px">
    <router-link :to="{ name: 'Geschichte' }" class="text-decoration-none text-white">
      <h2>Geschichte</h2>
    </router-link>
  </v-list-item>

  <v-list-item class="ma-0 pa-0" height="23px">
    <router-link :to="{ name: 'Mitglieder' }" class="text-decoration-none text-white">
      <h2>Mitglieder</h2>
    </router-link>
  </v-list-item>

  <v-list-item class="ma-0 pa-0" height="23px">
    <router-link :to="{ name: 'Termine' }" class="text-decoration-none text-white">
      <h2>Termine</h2>
    </router-link>
  </v-list-item>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
h2 {
  color: white;
}
</style>
