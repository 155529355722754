// Utilities
import { defineStore } from 'pinia'
import { type Mitglied } from '@/types/Mitglied'
import { type Hund } from '@/types/Hund'
import { ref, toRaw } from 'vue'

export const useAppStore = defineStore('app', () => {
  const cookies = ref<{ [keys: string]: string }>({})
  const webpSupport = ref(false)
  const elementSize = ref({
    nav: 0,
    footer: 0
  })
  const mitglieder = ref<Array<Mitglied>>([])
  const hunde = ref<Array<Hund>>([])

  // Cookies
  function setCookies(payload: { [keys: string]: string }) {
    cookies.value = payload
  }

  function getCookies(): { [keys: string]: string } {
    return toRaw(cookies.value)
  }

  // Webp
  function setWebpSupport(payload: boolean) {
    webpSupport.value = payload
  }

  function getWebpSupport(): boolean {
    return toRaw(webpSupport.value)
  }

  // Element Size
  function setElementSize(payload: { nav: number; footer: number }) {
    elementSize.value = payload
  }

  function getElementSize(): { nav: number; footer: number } {
    return elementSize.value
  }

  // Mitglieder
  function setMitglied(mitglied: Mitglied) {
    mitglieder.value.push(mitglied)
  }

  function getMitglieder(): Array<Mitglied> {
    return toRaw(mitglieder.value)
  }

  function getMitglied(name: string): Mitglied | null {
    let member = null as Mitglied | null

    mitglieder.value.forEach((mitglied) => {
      if (mitglied.name.toLowerCase() === name.toLowerCase()) {
        member = mitglied
      }
    })

    return toRaw(member)
  }

  // Hunde
  function setHund(hund: Hund) {
    hunde.value.push(hund)
  }

  function getHunde(): Array<Hund> {
    return toRaw(hunde.value)
  }

  function getHund(name: string): Hund | null {
    let hund = null as Hund | null

    hunde.value.forEach((doggo) => {
      if (doggo.name.toLowerCase() === name.toLowerCase()) {
        hund = doggo
      }
    })

    return toRaw(hund)
  }

  return {
    cookies,
    webpSupport,
    elementSize,
    mitglieder,
    hunde,
    setCookies,
    getCookies,
    setMitglied,
    getMitglieder,
    getMitglied,
    setHund,
    getHunde,
    getHund,
    setWebpSupport,
    getWebpSupport,
    setElementSize,
    getElementSize
  }
})
