<template>
  <v-card
    v-if="!showCookieConsent"
    :width="!displayStore.mobile || displayStore.width.value > 850 ? '800px' : '95%'"
    class="position-fixed pa-3"
    color="background"
    elevation="2"
    rounded
    style="
      right: 10px;
      bottom: 10px;
      border: 2px solid #821204;
      background: rgba(255, 239, 214, 0.9) !important;
      backdrop-filter: blur(10px);
    ">
    <v-card-title>
      <h3>Nutzung von Cookies:<br v-if="displayStore.mobile" />Wir benötigen Ihre Einwilligung</h3>
    </v-card-title>
    <v-card-subtitle>
      Wir verwenden auf dieser Webseite Cookies.
      <br v-if="displayStore.mobile" />Diese verarbeiten auch personenbezogene
      <br v-if="displayStore.mobile" />
      Daten.
    </v-card-subtitle>

    <v-card-text>
      <p>Zum Einsatz kommen auf unserer Seite:</p>

      <v-sheet class="ms-3" color="transparent">
        <v-checkbox
          v-model="technical"
          color="primary"
          density="compact"
          disabled
          hide-details
          label="Technisch notwendige Cookies"
          value="technical"></v-checkbox>
        <!--        <v-checkbox-->
        <!--          v-model="cookies"-->
        <!--          color="primary"-->
        <!--          density="compact"-->
        <!--          hide-details-->
        <!--          label="Statistik-Cookies"-->
        <!--          value="statistic"></v-checkbox>-->
        <v-checkbox
          v-model="cookies"
          color="primary"
          density="compact"
          hide-details
          label="Cookies von Drittanbietern (YouTube)"
          value="external-yt"></v-checkbox>
      </v-sheet>

      <!--      <p class="mt-2">-->
      <!--        Indem Sie auf „Cookie-Einstellungen“ klicken, erhalten Sie genauere Informationen zu unseren-->
      <!--        Cookies und können diese nach Ihren eigenen Bedürfnissen anpassen.-->
      <!--        <br /><br />-->
      <!--        Durch einen Klick auf das Auswahlfeld „Alle akzeptieren“ stimmen Sie der Verwendung aller-->
      <!--        Cookies zu, die unter „Cookie-Einstellungen“ beschrieben werden.-->
      <!--        <br /><br />-->
      <!--        Sie können Ihre Einwilligung zur Nutzung von Cookies zu jeder Zeit ändern oder widerrufen.-->
      <!--      </p>-->

      <p class="mt-2">
        Durch einen Klick auf das Auswahlfeld „Alle akzeptieren“ stimmen Sie der Verwendung aller
        oben aufgelisteten Cookies zu.
        <br /><br />
        Sie können Ihre Einwilligung zur Nutzung von Cookies zu jeder Zeit ändern oder widerrufen.
      </p>
    </v-card-text>

    <v-card-actions class="d-flex justify-end flex-wrap" style="gap: 10px">
      <!--      <v-btn>Cookie-Einstellungen</v-btn>-->
      <v-btn color="primary" variant="outlined" @click.prevent="onlyNecessary">
        Nur notwendige Cookies
      </v-btn>
      <v-btn color="primary" variant="flat" @click.prevent="acceptSelection">
        Auswahl akzeptieren
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" setup>
// TODO: Cookie-Einstellungen
// TODO: Statistic Cookies
import { getCookie, getCookies, setCookie } from 'typescript-cookie'
import { onMounted, ref } from 'vue'
import { useAppStore } from '@/store/app'
import { useDisplay } from 'vuetify'

const appStore = useAppStore()
const displayStore = useDisplay()

const showCookieConsent = ref(false)
const technical = 'technical'
const cookies = ref<Array<string>>([])

const consentGiven = (name: string) => {
  let consent = decodeURIComponent(getCookie('consent-given') || '')
  if (consent.includes(name)) return
  if (consent !== '') {
    consent += `,${name}`
  } else {
    consent = name
  }
  setCookie('consent-given', encodeURIComponent(consent), {
    expires: 365,
    secure: true,
    sameSite: 'strict'
  })
}

const acceptSelection = () => {
  consentGiven('technical')
  cookies.value.forEach((cookie) => {
    consentGiven(cookie)
  })
  appStore.setCookies(getCookies())
  showCookieConsent.value = Object.keys(appStore.getCookies()).length > 0
}

const onlyNecessary = () => {
  consentGiven('technical')
  cookies.value = []
  acceptSelection()
}

onMounted(() => {
  showCookieConsent.value = Object.keys(appStore.getCookies()).length > 0
})
</script>

<style lang="scss" scoped></style>
