<template>
  <v-sheet
    v-if="!mobileStore.getTablet() && !mobileStore.getMobile()"
    class="d-flex justify-center align-center position-sticky"
    color="background"
    height="120"
    style="
      border-radius: 50%;
      box-shadow: 0 0 10px 1px black;
      position: absolute;
      z-index: 20000 !important;
      top: 30px;
      left: 100px;
    "
    width="120">
    <v-img
      :src="appStore.getWebpSupport() ? logoWebp : logo"
      alt="logo"
      height="90%"
      style="border-radius: 50%; cursor: pointer"
      width="90%"
      @click.prevent="router.push({ name: 'Home' })" />
  </v-sheet>

  <v-app-bar
    :height="`${navHeight}px`"
    :scroll-behavior="mobileStore.getTablet() || mobileStore.getMobile() ? 'hide' : 'elevate'"
    :style="`padding: ${mobileStore.getTablet() || mobileStore.getMobile() ? '20px' : '28px'} ${
      mobileStore.getTablet() || mobileStore.getMobile() ? '20px' : '100px'
    }`"
    color="primary"
    location="top">
    <template v-slot:title>
      <v-sheet
        v-if="mobileStore.getTablet() || mobileStore.getMobile()"
        class="d-flex justify-center align-center elevation-2"
        color="background"
        height="60"
        style="border-radius: 50%; z-index: 20000 !important"
        width="60">
        <v-img
          :src="appStore.getWebpSupport() ? logoWebp : logo"
          alt="logo"
          height="90%"
          style="border-radius: 50%; cursor: pointer"
          width="90%"
          @click.prevent="router.push({ name: 'Home' })" />
      </v-sheet>
    </template>

    <template v-slot:append>
      <v-app-bar-nav-icon
        v-if="mobileStore.getTablet() || mobileStore.getMobile()"
        variant="text"
        @click.stop="drawer = !drawer" />

      <v-list
        v-else
        bg-color="transparent"
        class="d-flex align-center justify-end"
        style="font-size: 13px; gap: 35px"
        variant="text">
        <main-nav-links />
      </v-list>
    </template>
  </v-app-bar>

  <v-navigation-drawer
    v-model="drawer"
    :temporary="true"
    color="primary"
    location="end"
    style="padding-top: 120px">
    <v-list
      bg-color="transparent"
      class="d-flex flex-column justify-center align-center"
      style="font-size: 13px; gap: 35px"
      variant="text">
      <main-nav-links />
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import router from '@/router'
import { ref } from 'vue'
import { useMobileStore } from '@/store/displayStore'
import { useAppStore } from '@/store/app'

import MainNavLinks from '@/components/MainNav/MainNavLinks.vue'

import logo from '@/assets/images_optimized_jpg/logo.jpg'
import logoWebp from '@/assets/images_optimized_webp/logo.webp'

const mobileStore = useMobileStore()
const appStore = useAppStore()

const drawer = ref(false)
const navHeight = ref(appStore.getElementSize().nav)
</script>
