<template>
  <v-footer color="black">
    <v-sheet
      :class="
        mobileStore.getMobile() && !mobileStore.getTablet()
          ? 'd-flex flex-column align-center'
          : 'd-flex justify-space-between'
      "
      :min-height="`${footerHeight}px`"
      :style="`gap: ${
        mobileStore.getMobile() && !mobileStore.getTablet() ? '50px' : ''
      }; margin-left: ${width >= 1920 ? '17%' : width >= 1440 ? '6%' : '2.5%'}`"
      :width="width >= 1920 ? '66%' : width >= 1440 ? '88%' : '95%'"
      class="pt-4"
      color="transparent">
      <v-sheet
        :class="mobileStore.getMobile() && !mobileStore.getTablet() ? 'align-center' : ''"
        class="d-flex flex-column justify-start"
        color="transparent"
        style="gap: 6px">
        <router-link
          :to="{ name: 'Home' }"
          class="text-white text-decoration-none text-uppercase"
          style="font-size: 14px">
          Home
        </router-link>
        <router-link
          :to="{ name: 'AboutUs' }"
          class="text-white text-decoration-none text-uppercase"
          style="font-size: 14px">
          Über Uns
        </router-link>
        <router-link
          :to="{ name: 'Geschichte' }"
          class="text-white text-decoration-none text-uppercase"
          style="font-size: 14px">
          Geschichte
        </router-link>
        <router-link
          :to="{ name: 'Mitglieder' }"
          class="text-white text-decoration-none text-uppercase"
          style="font-size: 14px">
          Mitglieder
        </router-link>
        <router-link
          :to="{ name: 'Termine' }"
          class="text-white text-decoration-none text-uppercase"
          style="font-size: 14px">
          Termine
        </router-link>
      </v-sheet>

      <v-sheet
        :class="mobileStore.getMobile() && !mobileStore.getTablet() ? 'align-center' : ''"
        class="d-flex flex-column justify-start"
        color="transparent"
        style="gap: 6px">
        <router-link
          class="text-white text-decoration-none text-uppercase"
          onclick="window.open('mailto:kontakt@abtruennige.com?subject=Kontaktanfrage')"
          rel="noopener noreferrer"
          style="font-size: 14px"
          to="#">
          Kontakt
        </router-link>
        <router-link
          :to="{ name: 'Impressum' }"
          class="text-white text-decoration-none text-uppercase"
          style="font-size: 14px">
          Impressum
        </router-link>
        <router-link
          class="text-white text-decoration-none text-uppercase"
          style="font-size: 14px"
          to="#">
          Mitgliederbereich
        </router-link>
      </v-sheet>

      <v-sheet
        :class="mobileStore.getMobile() && !mobileStore.getTablet() ? 'align-start' : ''"
        class="d-flex justify-start"
        color="transparent"
        style="gap: 10px">
        <v-btn
          color="background"
          height="34"
          icon="mdi-facebook"
          onclick="window.open('https://www.facebook.com/DieAbtruennigen/', '_blank')"
          rounded
          width="34" />
        <v-btn
          color="background"
          height="34"
          icon="mdi-instagram"
          onclick="window.open('https://www.instagram.com/dieabtruennigen/', '_blank')"
          rounded
          width="34" />
      </v-sheet>
    </v-sheet>
  </v-footer>
</template>

<script lang="ts" setup>
import { useMobileStore } from '@/store/displayStore'
import { useDisplay } from 'vuetify'
import { useAppStore } from '@/store/app'
import { ref } from 'vue'

const mobileStore = useMobileStore()
const appStore = useAppStore()
const { width } = useDisplay()

const footerHeight = ref(appStore.getElementSize().footer)
</script>
