// Utilities
import { defineStore } from 'pinia'
import { reactive } from 'vue'

export const useMobileStore = defineStore('displayStore', () => {
  const display = reactive({
    mobile: false,
    tablet: false
  })

  function setMobile(payload: boolean) {
    display.mobile = payload
  }

  function setTablet(payload: boolean) {
    display.tablet = payload
  }

  function getMobile(): boolean {
    return display.mobile
  }

  function getTablet(): boolean {
    return display.tablet
  }

  return { setMobile, setTablet, getMobile, getTablet }
})
