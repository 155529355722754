import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/default/Default.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: 'about-us',
        name: 'AboutUs',
        component: () => import('@/views/AboutUs.vue')
      },
      {
        path: 'termine',
        name: 'Termine',
        // component: () => import('@/views/Termine.vue')
        redirect: { name: 'NotFound' }
      },
      {
        path: 'geschichte',
        name: 'Geschichte',
        // component: () => import('@/views/Geschichte.vue')
        redirect: { name: 'NotFound' }
      },
      {
        path: 'mitglieder',
        children: [
          {
            path: '',
            name: 'Mitglieder',
            component: () => import('@/views/Mitglieder.vue')
          },
          {
            path: ':name',
            name: 'Mitglied',
            component: () => import('@/views/Mitglied.vue')
          }
        ]
      },
      {
        path: 'impressum',
        name: 'Impressum',
        component: () => import('@/views/Impressum.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { ...savedPosition, behavior: 'smooth' }
    } else {
      return { top: 0, left: 0, behavior: 'smooth' }
    }
  }
})

export default router
